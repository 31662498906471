import axios from 'axios';
import mixitup from 'mixitup';

class FeedA1 {
    static COMPONENT_CLASS = 'o-feed-a-1';

    /**
     * The main block container
     * @type {HTMLElement}
     */
    container;

    /**
     * Button to open/hide filters block
     * @type {HTMLElement}
     */
    filterButton;

    /**
     * Filters Block
     * @type {HTMLElement}
     */
    filtersBlock;

    /**
     * The Calendar Block loader element
     * @type {HTMLElement}
     */
    loader;

    /**
     * Button HTMLElement that show more/less some filter lists
     * @type {HTMLCollection}
     */
    showMoreButtons;

    /**
     * Search button
     * @type {HTMLElement}
     */
    applyFiltersButton;

    /**
     * Clear Filters button
     * @type {HTMLElement}
     */
    clearFiltersButton;

    /**
     * Inputs which are used as block filters
     * @type {HTMLCollection}
     */
    filterInputs;

    /**
     * Block filters crumbs HTML Collection
     * @type {HTMLCollection}
     */
    filterCrumbs;

    /**
     * Mixer Instance for filters crumbs
     * @type {Mixer}
     */
    mixer;

    /**
     * Container of filters crumbs
     * @type {HTMLElement}
     */
    crumbsContainer;

    /**
     * Represents the current filter values used for fetching Event Slots
     * @typedef {Object}
     * @property {boolean} [initialized]     - Whether the block was initialized on JS side or not.
     * @property {string} [category]         - A comma separated list of categories IDs.
     * @property {string} [location]         - A comma separated list of locations IDs.
     * @property {string} [open_to]          - A comma separated list of open_to options.
     * @property {string} [s]                - Search field value.
     * @property {int} [paged]               - Current page.
     * @property {string} [order]            - Ordering direction.
     * @property {string} [orderby]          - What field should be considered for ordering.
     * @property {number} [posts_per_page]   - Maximum number of event slots to be fetch in a single query
     * @property {string} [include]          - A comma separated list of posts to show.
     * @property {string} [action]           - action value (for security purposes).
     * @property {string} [nonce]            - nonce value (for security purposes).
     */
    state = {
        initialized: {
            default: false,
            value: false,
            includeToUrl: true,
            urlKey: 'initialized',
            includeToFetch: false,
            fetchKey: '',
        },
        category: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'category',
            includeToFetch: true,
            fetchKey: 'course-type',
        },
        location: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'location',
            includeToFetch: true,
            fetchKey: 'event-location',
        },
        open_to: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'open_to',
            includeToFetch: true,
            fetchKey: 'open_to',
        },
        s: {
            default: '',
            value: '',
            includeToUrl: true,
            urlKey: 'search',
            includeToFetch: true,
            fetchKey: 's',
        },
        paged: {
            default: '1',
            value: '1',
            includeToUrl: true,
            urlKey: 'current_page',
            includeToFetch: true,
            fetchKey: 'paged',
        },
        order: {
            default: 'ASC',
            value: 'ASC',
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'order',
            constant: true,
        },
        orderby: {
            default: 'date',
            value: 'date',
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'orderby',
            constant: true,
        },
        posts_per_page: {
            default: 100,
            value: 100,
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'posts_per_page',
            constant: true,
        },
        include: {
            default: '',
            value: '',
            includeToUrl: false,
            urlKey: '',
            includeToFetch: true,
            fetchKey: 'include',
        },
        action: {
            default: window.feedA1.action,
            value: window.feedA1.action,
            includeToUrl: false,
            includeToFetch: true,
            fetchKey: 'action',
        },
        nonce: {
            default: window.feedA1.nonce,
            value: window.feedA1.nonce,
            includeToUrl: false,
            includeToFetch: true,
            fetchKey: 'nonce',
        },
        hide_outdated_classes: {
            default: '1',
            value: '',
            includeToUrl: false,
            includeToFetch: true,
            fetchKey: 'hide_outdated_classes',
            constant: true,
        },
    };

    /**
     * Array of filters items that can be selected by a user and it affects the REST API calls
     * @type {[{name: string, tag: string, type: string, queryParam: string, includedInMixer: boolean}]}
     */
    filtersItemsMap = [
        {
            name: 'course-type',
            tag: 'input',
            type: 'checkbox',
            queryParam: 'category',
            includedInMixer: true,
        },
        {
            name: 'event-location',
            tag: 'input',
            type: 'checkbox',
            queryParam: 'location',
            includedInMixer: true,
        },
        {
            name: 'open_to',
            tag: 'input',
            type: 'checkbox',
            queryParam: 'open_to',
            includedInMixer: true,
        },
        {
            name: 'search',
            tag: 'input',
            type: 'text',
            queryParam: 's',
            includedInMixer: false,
        },
    ];

    /**
     * URL for fetching classes
     * @type {string}
     */
    apiEndPoint;

    /**
     * Container with classes cards
     * @type {HTMLElement}
     */
    cardsContainer;

    /**
     * Container that includes some vital hidden inputs
     * @type {HTMLElement}
     */
    hiddenInputsContainer;

    /**
     * Container of the pagination elements
     */
    paginationBlock;

    /**
     * Creates an instance of the CalendarA1 class
     * @param {HTMLElement} container - The HTML element of the block container
     */
    constructor(container) {
        this.container = container;
        this.apiEndPoint = window.feedA1.url;

        // Determine all the needed elements
        this.initElements();
        // Checking initial state: we should define what is a single source for initial state: hidden inputs OR query params
        this.checkInit();
        // Setting an initial state for the block from the URL query params
        this.updateStateFromURL();
        // Set initial state for filter items
        this.setInitialState();
        // Add Event Listeners
        this.initListeners();
        // Initialize the Mixer class instance
        this.initMixer();
        // First fetch
        this.fetchClasses();
    }

    /**
     * Sets some elements as class properties
     */
    initElements() {
        this.loader = this.container.querySelector(`[data-role='feed-loader']`);
        this.filterButton = this.container.querySelector(
            `[data-role='filters-btn']`,
        );
        this.filtersBlock = this.container.querySelector(
            `[data-role='filters-block']`,
        );
        this.showMoreButtons = this.container.querySelectorAll(
            `[data-role='show-more-btn']`,
        );
        this.applyFiltersButton = this.container.querySelector(
            `[data-role='filtering-submit-btn']`,
        );
        this.clearFiltersButton = this.container.querySelector(
            `[data-role='filtering-clear-btn']`,
        );
        this.crumbsContainer = this.container.querySelector(
            `[data-role='crumbs-container']`,
        );
        this.filterInputs = this.container.querySelectorAll(
            `[data-role='filtering-input']`,
        );
        this.filterCrumbs = this.container.querySelectorAll(
            `[data-role='filters-crumb']`,
        );
        this.cardsContainer = this.container.querySelector(
            `[data-role='cards-container']`,
        );
        this.hiddenInputsContainer = this.container.querySelector(
            `[data-role='hidden-inputs']`,
        );
        this.paginationBlock = this.container.querySelector(
            `[data-role='pagination-block']`,
        );
    }

    /**
     * Add needed event listeners
     */
    initListeners() {
        if (this.filterButton) {
            this.filterButton.addEventListener(
                'click',
                this.filtersBlockButtonHandler.bind(this),
            );
        }
        if (this.showMoreButtons.length) {
            [...this.showMoreButtons].forEach((button) => {
                button.addEventListener(
                    'click',
                    this.showMoreButtonsHandler.bind(this),
                );
            });
        }
        if (this.applyFiltersButton) {
            this.applyFiltersButton.addEventListener(
                'click',
                this.applyFiltersButtonHandler.bind(this),
            );
        }
        if (this.clearFiltersButton) {
            this.clearFiltersButton.addEventListener(
                'click',
                this.clearFiltersButtonHandler.bind(this),
            );
        }
        if (this.filterInputs.length) {
            [...this.filterInputs].forEach((input) => {
                input.addEventListener(
                    'change',
                    this.filteringInputChangeHandler.bind(this),
                );
            });
        }
        if (this.filterCrumbs.length) {
            [...this.filterCrumbs].forEach((crumb) => {
                crumb.addEventListener(
                    'click',
                    this.filtersCrumbClickHandler.bind(this),
                );
            });
        }
        if (this.paginationBlock) {
            this.paginationBlock.addEventListener(
                'click',
                this.paginationHandler.bind(this),
            );
        }
    }

    /**
     * Initializes a new Mixer Instance for filters items
     */
    initMixer() {
        if (!this.crumbsContainer) {
            return;
        }

        // Building selector for initial filtering
        let selectorsFromUrl = this.filtersItemsMap
            .filter((item) => {
                return item.includedInMixer;
            })
            .map((item) => {
                return this.state[item.queryParam] &&
                    this.state[item.queryParam].value
                    ? this.state[item.queryParam].value.split(',')
                    : [];
            })
            .flat()
            .map((value) => {
                return `[data-role='filters-crumb'][data-term-id='${value}']`;
            })
            .join(',');

        this.mixer = mixitup(this.crumbsContainer, {
            load: {
                filter: 'none',
            },
            callbacks: {
                onMixStart: (state, futureState) => {
                    this.crumbsContainer.classList.remove('opacity-0');
                },
            },
        });

        // If we have a non-empty selector - run the first mix
        if (selectorsFromUrl) {
            this.mixer.filter(selectorsFromUrl);
        }
    }

    /**
     * Fetching Event Slots from backend side by axios request
     */
    fetchClasses() {
        this.showLoader();

        // Building object of query params from the state object
        let data = new FormData();
        Object.entries(this.state)
            .filter(([key, value]) => value.includeToFetch && value.fetchKey)
            .forEach(([key, value]) => {
                data.append(value.fetchKey, value.value);
            });
        axios
            .post(this.apiEndPoint, data)
            .then((response) => {
                const responseData = response.data;
                if (responseData && responseData.success && responseData.data) {
                    if (responseData.data.cardsHtml) {
                        this.cardsContainer.innerHTML =
                            responseData.data.cardsHtml;
                    }
                    if (responseData.data.paginationHtml) {
                        this.paginationBlock.innerHTML =
                            responseData.data.paginationHtml;
                    }
                } else if (
                    responseData &&
                    !responseData.success &&
                    responseData.data
                ) {
                    console.error(`Error: ${responseData.data.data}`);
                }
            })
            .catch((error) => {
                // Handle the failed response
                console.error('Error:', error);
            })
            .finally(() => {
                this.hideLoader();
            });
    }

    /**
     * Shows a loader above the whole calendar container
     */
    showLoader() {
        if (!this.loader) {
            return;
        }

        this.loader.dataset.visible = 'true';
    }

    /**
     * Hides a loader
     */
    hideLoader() {
        if (!this.loader) {
            return;
        }

        this.loader.dataset.visible = 'false';
    }

    /**
     * Updates the global state object for existing keys
     * @param {Object} params
     * @param {Boolean} updateUrl
     */
    updateState(params, updateUrl = true) {
        // Set initialized value to true for any case
        this.state.initialized.value = true;
        // Set state values for other keys
        for (const key in params) {
            if (this.state[key] !== undefined) {
                this.state[key].value = params[key];
            }
        }

        if (updateUrl) {
            this.updateURLFromState();
        }
    }

    /**
     * Show/Hide filters block
     * @param event
     */
    filtersBlockButtonHandler(event) {
        event.preventDefault();

        if (!this.filtersBlock) {
            return;
        }

        const isVisible = this.filtersBlock.dataset.visible === 'true';
        if (isVisible) {
            this.hideFiltersBlock();
        } else {
            this.showFiltersBlock();
        }
    }

    /**
     * Opens the filters block
     */
    showFiltersBlock() {
        jQuery(this.filtersBlock).slideDown(400, () => {
            this.filtersBlock.dataset.visible = 'true';
        });
    }

    /**
     * Hides the filters block
     */
    hideFiltersBlock() {
        jQuery(this.filtersBlock).slideUp(400, () => {
            this.filtersBlock.dataset.visible = 'false';
        });
    }

    /**
     * Fires when user clicks the Search button
     * @param event
     */
    applyFiltersButtonHandler(event) {
        event.preventDefault();

        this.updateStateByFilters();
        this.hideFiltersBlock();
        this.fetchClasses();
    }

    /**
     * Fires when the 'Clear' button is clicked
     * @param event
     */
    clearFiltersButtonHandler(event = null) {
        event.preventDefault();

        this.filtersItemsMap.forEach((filterItem) => {
            if (filterItem.tag === 'input') {
                if (filterItem.type === 'checkbox') {
                    // Processing input[type='checkbox']
                    let checkedInputs = this.container.querySelectorAll(
                        `input[type='checkbox'][name='${filterItem.name}']:checked`,
                    );
                    if (!checkedInputs.length) {
                        return;
                    }
                    checkedInputs.forEach((input) => {
                        input.checked = false;
                    });
                } else if (filterItem.type === 'text') {
                    // Processing input[type='text']
                    let input = this.container.querySelector(
                        `input[type='text'][name=${filterItem.name}]`,
                    );
                    if (!input) {
                        return;
                    }
                    input.value = '';
                }
            }
        });

        this.filteringInputChangeHandler();
    }

    /**
     * Processing all the filters inputs specified in the filtersItemsMap array and use these filters values for updating the state class parameter
     */
    updateStateByFilters() {
        const filtersData = {};
        // Flag that determines whether the state has been changed or not. Need this for updating page value
        let wasChanged = false;
        // Looping thorough all the filter items
        this.filtersItemsMap.forEach((filterItem) => {
            if (filterItem.tag === 'input' && filterItem.type === 'checkbox') {
                // Processing input[type='checkbox']
                let allInputs = this.container.querySelectorAll(
                    `input[type='checkbox'][name=${filterItem.name}]`,
                );
                if (!allInputs.length) {
                    return;
                }
                let checkedInputs = [];
                allInputs.forEach((input) => {
                    if (input.checked) {
                        checkedInputs.push(input.value);
                    }
                });
                filtersData[filterItem.queryParam] = checkedInputs.join(',');
                if (
                    this.state[filterItem.queryParam] !==
                    filtersData[filterItem.queryParam]
                ) {
                    wasChanged = true;
                }
            } else if (
                filterItem.tag === 'input' &&
                filterItem.type === 'text'
            ) {
                // Processing input[type='text']
                let input = this.container.querySelector(
                    `input[type='text'][name=${filterItem.name}]`,
                );
                if (!input) {
                    return;
                }
                filtersData[filterItem.queryParam] = input.value;
                if (
                    this.state[filterItem.queryParam] !==
                    filtersData[filterItem.queryParam]
                ) {
                    wasChanged = true;
                }
            }
        });
        if (wasChanged) {
            //console.log('changed'); // todo - paged param does not work properly here
            this.setInitialStateValues();
        }
        // Get hidden inputs values
        // We need to do this everytime as we might change page input value for example
        const hiddenInputsData = this.getHiddenInputsStateParams();
        for (const key in hiddenInputsData) {
            const value = hiddenInputsData[key];
            if (Object.keys(this.state).includes(key)) {
                filtersData[key] = value;
            }
        }
        this.updateState(filtersData);
    }

    /**
     * Fires on filters input change Event OR it can be executed manually.
     * It loops over all the filter items and show/hide their crumbs with Mixer
     */
    filteringInputChangeHandler() {
        if (this.mixer) {
            let checked = Array.from(this.filterInputs)
                .filter((checkbox) => {
                    return checkbox.checked;
                })
                .map((checkbox) => {
                    return `[data-role='filters-crumb'][data-term-id='${checkbox.value}']`;
                });
            this.mixer.filter(checked.length > 0 ? checked.join(',') : 'none');
        }
    }

    /**
     * Fires when a user clicks a filters crumb
     * @param {Event} event
     */
    filtersCrumbClickHandler(event) {
        event.preventDefault();

        const target = event.target;
        const crumb = target.closest(`[data-role='filters-crumb']`);
        if (!crumb) {
            return;
        }
        const value = crumb.dataset.termId ?? '';
        if (!value) {
            return;
        }

        [...this.filterInputs].forEach((input) => {
            if (input.value === String(value)) {
                input.checked = false;
                input.dispatchEvent(
                    new Event('change', {
                        bubbles: true,
                    }),
                );
            }
        });

        this.updateStateByFilters();
        this.hideFiltersBlock();
        this.fetchClasses();
    }

    /**
     * Modifies the state parameter of the class, setting values from the URL query params
     */
    updateStateFromURL() {
        const urlParams = new URLSearchParams(window.location.search);

        const newState = {};
        for (const key in this.state) {
            if (this.state[key].includeToUrl && this.state[key].urlKey) {
                const queryKey = this.state[key].urlKey;
                if (urlParams.get(queryKey)) {
                    newState[key] = String(urlParams.get(queryKey));
                } else {
                    newState[key] = this.state[key].default;
                }
            }
        }
        this.updateState(newState, false);
    }

    /**
     * Updates the current page URL by adding query params associated with the state object
     */
    updateURLFromState() {
        const urlParams = new URLSearchParams(window.location.search);

        // Set query parameters from the object
        for (let key in this.state) {
            if (!this.state[key].includeToUrl) {
                continue;
            }
            const urlKey = this.state[key].urlKey;
            if (urlKey) {
                if (
                    this.state[key].value === '' ||
                    this.state[key].value === this.state[key].default
                ) {
                    urlParams.delete(urlKey);
                } else {
                    urlParams.set(urlKey, this.state[key].value);
                }
            }
        }
        const newURL = window.location.pathname + '?' + urlParams.toString();

        // Update the URL without triggering a page reload
        history.replaceState({}, '', newURL);
    }

    /**
     * Set initial state for various block features
     */
    setInitialState() {
        // 1. Filter inputs
        this.filtersItemsMap.forEach((item) => {
            const stateValue = this.state[item.queryParam].value ?? '';

            if (item.tag === 'input') {
                if (item.type === 'checkbox') {
                    const inputs = this.container.querySelectorAll(
                        `input[type='checkbox'][name='${item.name}']`,
                    );
                    const checkedInputs = stateValue
                        ? stateValue.split(',')
                        : [];
                    [...inputs].forEach((input) => {
                        input.checked = checkedInputs.includes(input.value);
                    });
                } else if (item.type === 'text') {
                    let input = this.container.querySelector(
                        `input[type='text'][name=${item.name}]`,
                    );
                    input.value = String(stateValue);
                }
            }
        });
        // 2. Mixer - done on init
    }

    /**
     * Shows/Hides some lists in the filters block
     * @param event
     */
    showMoreButtonsHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='show-more-btn']`);
        const listsContainer = target.closest(`[data-role='filtering-column']`);
        if (!listsContainer) {
            return;
        }
        const hiddenList = listsContainer.querySelector(
            `[data-role='filtering-hidden-list']`,
        );
        if (!hiddenList) {
            return;
        }
        const isVisible = hiddenList.dataset.visible === 'true';
        const buttonLabel = button.querySelector(
            `[data-role='show-more-btn-label']`,
        );
        const buttonIcon = button.querySelector(
            `[data-role='show-more-btn-icon']`,
        );
        if (isVisible) {
            jQuery(hiddenList).slideUp(400, () => {
                buttonLabel.innerText = 'Show More';
                hiddenList.dataset.visible = (!isVisible).toString();
            });
            buttonIcon.classList.add('rotate-180');
        } else {
            jQuery(hiddenList).slideDown(400, () => {
                buttonLabel.innerText = 'Show Less';
                hiddenList.dataset.visible = (!isVisible).toString();
            });
            buttonIcon.classList.remove('rotate-180');
        }
    }

    /**
     * Check the initial state of the block.
     * If the 'initialized=true' query parameter is present, it indicates that hidden input values do not need to be considered.
     * This caters to the scenario where a user shares a URL with predefined query parameters to another person.
     * If the 'initialized' query parameter is absent, the function iterates through all hidden inputs and assigns their values to establish the initial block state.
     * Values for the hidden inputs are being received from the block settings on the admin page.
     * This handles the scenario where a user opens the page with no query parameters.
     */
    checkInit() {
        const urlParams = new URLSearchParams(window.location.search);
        const isInitialized = urlParams.get('initialized') === 'true';
        const hiddenInputsStateValues = this.getHiddenInputsStateParams();
        const initialStateValues = {};

        if (isInitialized) {
            for (const key in this.state) {
                const value = this.state[key];
                if (value.constant) {
                    initialStateValues[key] = hiddenInputsStateValues[key];
                }
            }
            this.updateState(initialStateValues, false);
            return;
        }

        for (const key in hiddenInputsStateValues) {
            const value = hiddenInputsStateValues[key];
            if (Object.keys(this.state).includes(key)) {
                initialStateValues[key] = value;
            } else {
                if (key === 'preselected_categories') {
                    initialStateValues.category = value;
                } else if (key === 'preselected_locations') {
                    initialStateValues.location = value;
                } else if (key === 'preselected_open_to') {
                    initialStateValues.open_to = value;
                } else if (key === 'preselected_posts') {
                    initialStateValues.include = value;
                }
            }
        }

        this.updateState(initialStateValues);
    }

    /**
     * Iterate through all hidden inputs and create an object where each input's name corresponds to its value.
     * Returns an object with input names as keys and their respective values as values.
     * @returns {{}}
     */
    getHiddenInputsStateParams() {
        if (!this.hiddenInputsContainer) {
            return {};
        }
        const inputs =
            this.hiddenInputsContainer.querySelectorAll(`input[type='hidden']`);
        const data = {};
        inputs.forEach((input) => {
            if (input.name) {
                data[input.name] = input.value;
            }
        });

        return data;
    }

    /**
     * Set initial values for certain hidden inputs:
     * page = 1, include = ''
     */
    setInitialStateValues() {
        if (!this.hiddenInputsContainer) {
            return;
        }
        const pageInput = this.hiddenInputsContainer.querySelector(
            'input[name="paged"]',
        );
        if (pageInput) {
            pageInput.value = 1;
        }
        const includeInput = this.hiddenInputsContainer.querySelector(
            'input[name="include"]',
        );
        if (includeInput) {
            includeInput.value = '';
        }
    }

    /**
     * Fires on clicks on the pagination block. Updates the current page value
     * @param {Event} event
     */
    paginationHandler(event) {
        const target = event.target;
        const button = target.closest(`[data-role='pagination-button']`);
        if (!button || button.disabled) {
            return;
        }

        event.preventDefault();
        const page = parseInt(button.dataset.value, 10);
        this.updateState({
            paged: page,
        });
        this.hideFiltersBlock();
        this.scrollToBlockTop();
        this.fetchClasses();
    }

    /**
     * Smooth scrolling to the upper part of the block.
     */
    scrollToBlockTop() {
        const offset = 60;
        const elementPosition = this.container.getBoundingClientRect().top;

        window.scrollTo({
            top: elementPosition + window.scrollY - offset,
            behavior: 'smooth',
        });
    }
}

function feedA1() {
    // Initializes the necessary components for the first block on the page.
    // This function avoids using querySelectorAll since multiple blocks on the same page won't present.
    const feedBlock = document.querySelector(`[data-role='feed-block']`);
    if (!feedBlock) {
        return;
    }
    // Whether the block has already been initialized or not
    let isInit = false;
    // Adding two event listeners: on load and on scroll events
    window.addEventListener('load', initFeedA1, true);
    document.addEventListener('scroll', initFeedA1, true);

    // Check whether the block's container is visible and within the viewport, and initializes a new FeedA1 class instance if so
    function initFeedA1() {
        if (!isInit && isVisible(feedBlock)) {
            // Initialize a new class instance
            new FeedA1(feedBlock);
            // Set the flag's value
            isInit = true;
            // Remove event listeners
            removeListeners();
        }
    }

    // Removes event listeners
    function removeListeners() {
        window.removeEventListener('load', initFeedA1, true);
        document.removeEventListener('scroll', initFeedA1, true);
    }
}

/**
 * Returns true if the passed element is visible and within the viewport
 * @param element
 * @returns {boolean}
 */
function isVisible(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.bottom > 0 &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
    );
}

export default feedA1;
